import React from "react";
import theme from "theme";
import { Theme, Span, Text, Link, Box, Section, Image, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckmarkCircle } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				PlayFooty
			</title>
			<meta name={"description"} content={"PlayFooty"} />
			<meta property={"og:image"} content={"none"} />
			<link rel={"shortcut icon"} href={"https://inkzstar-place.com/img/8133849.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<meta name={"msapplication-TileImage"} content={"https://inkzstar-place.com/img/8133849.png"} />
		</Helmet>
		<Components.Header />
		<Section min-height="80vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					<Span
						color="--primary"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						PlayFooty
					</Span>
					{" "}
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					PlayFooty'de yüksek kaliteli bir futbol sahası rezerve edin. Bakımlı sahalarımız, maçlar, antrenmanlar ve etkinlikler için mükemmeldir. Harika olanakların ve esnek rezervasyon seçeneklerinin tadını çıkarın.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact"
						padding="12px 24px 12px 24px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						background="--color-primary"
						hover-background="--color-primary"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Rezervasyon Yap
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://inkzstar-place.com/img/1.jpg) center center/cover no-repeat scroll padding-box"
			/>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-8">
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-columns="1fr"
				align-items="start"
				width="100%"
				min-width="100%"
			/>
			<Box
				display="flex"
				padding="0px 0px 0px 30px"
				margin="0px -10px 0px 0px"
				lg-margin="0px 10px 0px 10px"
				lg-padding="0px 0px 0px 0"
				min-width="100px"
				height="100%"
			>
				<Image
					src="https://inkzstar-place.com/img/2.jpg"
					display="block"
					border-radius="25px"
					object-fit="cover"
				/>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 60px 0px 60px"
				lg-margin="0px 0px 50px 0px"
				sm-padding="0px 25px 0px 25px"
			>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					PlayFooty'e Hoş Geldiniz
				</Text>
				<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
					PlayFooty'e hoş geldiniz, futbol sahası kiralamak için başlıca noktanız! Tesisimiz, amatörler ve profesyonel oyuncuların ihtiyaçlarını karşılamak için tasarlanmış birinci sınıf bir sahaya sahiptir. Arkadaş canlısı bir maç, rekabetçi bir oyun veya antrenman için bir yer arıyorsanız, sahamız olağanüstü bir oyun deneyimi sunmaya hazırdır.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Yüksek kaliteli hizmet: Müşteri memnuniyetine öncelik veriyor ve beklentilerinizi aşmaya çalışıyoruz.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Modern ekipman: Futbol sahamız ve ekipmanlarımız en yüksek standartlara uygundur.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Esneklik: Çeşitli kiralama seçenekleri ve ek hizmetler sunuyoruz.
					</Text>
				</Box>
				<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
					Futbol sahamız, oyun için en uygun koşulları sağlamak için titizlikle bakım yapılmaktadır. Yüksek kaliteli kaplama ve yeterli alan ile küçük takımların antrenmanlarından büyük ölçekli turnuvalara kadar her şeyi karşılar. Ayrıca, duşlar ve dinlenme alanları gibi modern olanaklar sunarak deneyiminizi daha da geliştirmeyi amaçlıyoruz.
				</Text>
			</Box>
		</Section>
		<Components.Photos />
		<Components.Footer />
	</Theme>;
});